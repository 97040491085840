import ClockIcon from "../assets/images/clock.gif"
import RobotIcon from "../assets/images/robot.gif"
import EngineIcon from "../assets/images/gear.gif"

export const homePageAnchorComponentData = [
  {
    scrollToSection: "top",
    title: "Record Time To Hire",
    src: ClockIcon,
    alt:"clock gif"
  },
  {
    scrollToSection: "zigzags",
    title: "Automation & AI Usage",
    src: RobotIcon,
    alt: "robot gif"
  },
  {
    scrollToSection: "testimonials",
    title: "Engineers Hire Engineers",
    src: EngineIcon,
    alt:"gear gif"
  },
]
