import React from "react"
import "./ChyronItem.scss"

/* A custom chyron component.
@param data - Object, eg. {src: exampleImage, alt: "Some alter text"}
*/
const ChyronItem = ({ data }) => {
  const { src, alt, url } = data
  return (
    <a
      className="chyron-image"
      href={url}
      title={alt}
      target="_blank"
      rel="noreferrer"
    >
      <img src={src} alt={alt} width="auto" height="64px" />
    </a>
  )
}

export default ChyronItem
