import React, { useMemo } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Home.scss"
import Anchor from "../../components/Anchor/Anchor"
import TopComponent from "../../components/TopComponent/TopComponent"
import { homePageAnchorComponentData } from "../../data/anchor-component-data"
import ZigZag from "../../components/ZigZag/ZigZag"
import ChyronItem from "../../components/ChyronItem/ChyronItem"
import Contact from "../../components/Contact/Contact"
import { Layout } from "../../layouts"
import Projects from "../../components/Projects/Projects"
import Testimonial from "../../components/Testimonial/Testimonial"
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../constants"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import gifImage from "../../assets/images/gif-images/gif-cube.svg"
import SEO from "../../components/Seo"
import { localizeDataNodes, localizeStaticNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"


const ZIGZAG_IMAGE_SIDE_START = "right"

const sectionsIds = [
  { top: "section-top" },
  { clients: "section-clients" },
  { projects: "section-projects" },
  { zigzags: "section-zigzags" },
  { testimonials: "section-testimonials" },
  { contact: "section-contact" },
  { footer: "section-footer" },
]

const MAX_NUMBER_OF_PROJECTS = 3

const Home = ({ pageContext: { pageData, lang, projectImages, clients, testimonials, companies }, data, location }) => {
  const {
    allDzemoApiZigzags,
  } = useMemo(() => localizeDataNodes(data, lang), [data, lang])

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])
  const localizedZigzags = useMemo(() => localizeStaticNodes(pageData[lang].zigzags, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={"/"}
        data={pageData[lang]}
      />
      <div className="home-page">
        <section
          id={sectionsIds.find(sectionId => sectionId.top)?.top}
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"hp"} />
          )}
        </section>
        <div className="gif-container">
          <img src={gifImage} alt="square" width="85" height="72" />
        </div>
        <section
          id={sectionsIds.find(sectionId => sectionId.clients)?.clients}
          className="chyron-container"
        >
          <TestimonialSlider chyron={true}>
            {clients.map(client => {
              const { id, logo, webAddress, staticLogo } = client.node[lang]
              const { alternativeText } = logo
              const { publicUrl } = staticLogo

              return (
                <ChyronItem
                  key={id}
                  data={{
                    src: `${publicUrl}`,
                    alt: alternativeText,
                    url: webAddress,
                  }}
                />
              )
            })}
          </TestimonialSlider>
        </section>
        <div className="anchors-container">
          {homePageAnchorComponentData.map(anchor => {
            const { title, src, alt, scrollToSection } = anchor
            return (
              <Anchor
                key={title}
                title={title}
                scrollElementId={
                  sectionsIds.find(sectionId => sectionId[scrollToSection])[
                    scrollToSection
                  ]
                }
                src={src}
                alt={alt}
              />
            )
          })}
        </div>
        <section
          id={sectionsIds.find(sectionId => sectionId.projects)?.projects}
          style={{
            display: "flex",
            background: "black",
            minHeight: "250px",
            height: "720px",
            width: "100%",
          }}
        >
          {projectImages.length > 0 && (
            <Projects
              data={projectImages.slice(0, MAX_NUMBER_OF_PROJECTS)}
            />
          )}
        </section>{" "}
        <section
          id={sectionsIds.find(sectionId => sectionId.zigzags)?.zigzags}
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
            {localizedZigzags.length > 0 &&
            localizedZigzags.map((zigzag, index) => {
              return (
                <ZigZag
                  key={zigzag.id}
                  data={{
                    ...zigzag,
                    image: zigzag.image[0],
                    sideOfImage: zigzagImageSides[index],
                    wave: index === 0 ? true : undefined,
                  }}
                />
              )
            })}
        </section>
        <section
          id={
            sectionsIds.find(sectionId => sectionId.testimonials)?.testimonials
          }
          style={{
            background: "rgba(241, 241, 241, 1)",
            minHeight: "717px",
          }}
        >
          <TestimonialSlider>
            {testimonials.map(testimonial => {
              const { id } = testimonial.node[lang]
              return <Testimonial key={id} data={testimonial.node[lang]} />
            })}
          </TestimonialSlider>
        </section>
        <section
          id={sectionsIds.find(sectionId => sectionId.contact)?.contact}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </Layout>
  )
}
export default Home

export const query = graphql`
  query homePageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              hp {
                height
                width
                name
                size
                url
              }
              hp_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              hp {
                height
                width
                name
                size
                url
              }
              hp_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiClients {
      nodes {
        en {
          id
          webAddress
          logo {
            caption
            alternativeText
            id
            name
            size
            url
            width
            height
          }
        }
        de {
          id
          webAddress
          logo {
            caption
            alternativeText
            id
            name
            size
            url
            width
            height
          }
        }
      }
    }

    allDzemoApiProjects(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          teaserText
          route
          name
          image {
            caption
            alternativeText
            formats {
              project {
                height
                width
                url
              }
            }
          }
        }
        de {
          id
          teaserText
          route
          name
          image {
            caption
            alternativeText
            formats {
              project {
                height
                width
                url
              }
            }
          }
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { page: { id: { eq: $pageId } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
      }
    }
  }
`
