import React, { useContext } from "react"
import { Link } from "gatsby"
import Stories from "../Stories/Stories"
import "./Projects.scss"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"
import { LANGUAGES } from "../../constants"
//const API_URL = process.env.GATSBY_API_URL

const DURATION_OF_ONE_STORY = 4000

/* A custom projects component.
@param data - Object, eg. {image: exampleImage, heading: "Example heading"}
image - String representing image location, eg. "/static/example-image.png"
heading - String, eg. "Example heading"
@param duration - Number representing duration of slide in milliseconds, eg. 1500
@param children - JSX Object, eg. <div>Some content</div
*/
const Projects = ({ data }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  return (
    <div className="projects-component">
      <div className="projects-component-story-container">
        <h2 className="image-container-title">{`${translateText(
          "Projects",
          currentLanguage.fullTitle,
          googleSheetData
        )}`}</h2>
        <Stories transparent duration={DURATION_OF_ONE_STORY}>
          {data.map(item => {
            const { name, teaserText, id, route, image } = item.node.en
            const { project } = image[0].staticImages

            return (
              <div
                key={id}
                className="project-component-story-content"
                style={{ display: "flex" }}
              >
                <div className="projects-component-image-container">
                  <>
                    <h2
                      className="image-container-title"
                      style={{ opacity: 0 }}
                    >{`${translateText(
                      "Projects",
                      currentLanguage.fullTitle,
                      googleSheetData
                    )}`}</h2>

                    <img
                      src={`${project[0].src}`}
                      alt={image.alternativeText}
                    />
                  </>
                </div>
                <div className="projects-component-description-container">
                  <div className="projects-component-text-content">
                    <h2 className="title">{name}</h2>
                    <p className="description">{teaserText}</p>
                  </div>
                  {route && (
                    <Link
                      className="btn btn-outline-light"
                      style={{ zIndex: 10000 }}
                      to={`${routePrefix}/projects${route}`}
                    >
                      {`${translateText(
                        "See case",
                        currentLanguage.fullTitle,
                        googleSheetData
                      )} →`}
                    </Link>
                  )}
                </div>
              </div>
            )
          })}
        </Stories>
      </div>
    </div>
  )
}

export default Projects
