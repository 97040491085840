import React, { useContext } from "react"
import { Link } from "react-scroll"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"
import "./Anchor.scss"

/* A custom anchor component.
@param title - String, eg. "Some title"
@param icon - SVG image, eg. import { ReactComponent as ExampleIcon }
@param scrollElementId - String, eg. "elementId1"
*/
const Anchor = props => {
  const { title, src, alt, scrollElementId } = props

  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  return (
    <div className="anchor-content">
      <Link to={scrollElementId} smooth={true}>
        {/* <props.icon /> */}
        <img src={src} alt={alt} width="" height="" />
        <div className="anchor-text">
          {translateText(title, currentLanguage.fullTitle, googleSheetData)}
        </div>
      </Link>
    </div>
  )
}

export default Anchor
